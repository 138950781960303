import React, { ReactElement, useState } from 'react';
import './Rules.scss';

const Rules: React.FC = (): ReactElement => {
  const [RulesVisible, setRulesVisible] = useState<boolean>(true);

  return (
    <div className="Rules">
      {/* <div className="button-div">
        <a
          className="rules-nav-btn"
          href="#rules-div"
          onClick={() => setRulesVisible(true)}
        >
          KAMPAANIAREEGLID
        </a>
      </div> */}
      <div
        className={'rules-container ' + (RulesVisible ? '' : 'hidden')}
        id="rules-div"
      >
        {/* <button
          className="close-btn"
          onClick={() => {
            setRulesVisible(false);
          }}
        >
          <span className="first"></span>
          <span className="second"></span>
        </button> */}
        <div className="rules-wrapper">
          <h2>KAMPAANIAREEGLID</h2>
          <ol>
            <li>
              Kampaania{' '}
              <span className="bold">“Geisha – Lase armastusel õitseda ”</span>{' '}
              on alates <span className="bold">01.02.2025</span> kuni{' '}
              <span className="bold">28.02.2025</span> toimuv tarbijamäng
              (edaspidi kampaania), mida korraldab Fazer Eesti OÜ, (asukoht:
              Pärnu maantee 158/1, Tallinn; edaspidi korraldaja).
            </li>
            <li>
              {' '}
              Kampaania auhinnafondi kuulub{' '}
              <span className="bold">
                5x romantiline minipuhkus kahele Schloss Fellin butiikhotellis
              </span>{' '}
              mis loositakse välja 03.03.2025. Auhinnafondi koguväärtus on
              4250€. Üks auhind sisaldab: .{' '}
              <ul>
                <li>Pakett kahele majutuse, õhtusöögi ja Spaa hoolitsustega</li>
                <li>Deluxe kahene tuba, 1 öö</li>
                <li>hommikusöök hotelli kohvikus</li>
                <li>
                  wellness butiikspaa saunade, lõõgastusbasseini ja mullivanni
                  kasutamine
                </li>
                <li>hommikumantlite ja susside kasutamine</li>
                <li>pudelivesi saabumisel, kohvi ja tee tarvikud toas</li>
                <li>WiFi</li>
                <li>
                  tasuta parkimine hotelli parklas vabade kohtade olemasolul
                </li>
                <li>
                  Õhtusöök kahele restoranis Schloss, peakoka 5-käigulise
                  maitsemenüüga. Restoran on avatud N-L 17.00-23.00 (köök kuni
                  22.00)
                </li>
                <li>
                  Spaa hoolitsused kahele{' '}
                  <ul>
                    <li>
                      Jaapani Ko Bi Do Näo- ja kaelapiirkonna noorendav
                      massaažirituaal, ekspresshooldus 20min
                    </li>
                    <li>
                      Ikooniline noorendav ning lõõgastav massaažirituaal näole
                      ja kehale 50 min Sisseregistreerimine alates kell 15.00 ja
                      väljaregistreerimine hiljemalt kell 12.00. Spaa saunade ja
                      basseiniala on avatud 12.00 – 22.00.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Kampaania läbiviimise kord on kehtestatud korraldaja poolt
              käesolevates reeglites. Kõik korraldaja otsused kampaania
              läbiviimisel on lõplikud ning kõigile kampaanias osalejatele
              kohustuslikud.
            </li>
            <li>
              Kampaaniatooted on{' '}
              <span className="bold">
                Geisha piimašokolaad 100g, Geisha Caramel ja Sea salt
                piimašokolaad 100g, Geisha Crispy Dream piimašokolaad 100g,
                Geisha Crunchy šokolaadibatoon 50g, Geisha kommikarp 150g,
                Geisha Dark kommikarp 150g, Geisha Caramel ja Sea salt kommikarp
                150g, Geisha kommikarp 270g, Geisha kommikarp 225g, Geisha
                kommikarp 185g,Geisha piimašokolaadikompvekid 3kg, Geisha
                küpsised 100g, Geisha piimašokolaadibatoon 37g.
              </span>{' '}
              (edaspidi tooted).
            </li>
            <li>
              Kampaanias osalemiseks tuleb osta vähemalt üks Geisha toode ja
              registreerida ostutšekk interneti kaudu kampaanialehel
              <a href="http://www.geisha.ee"> www.geisha.ee</a>. Kampaanias
              osalemine on tasuta. Auhinna kättesaamiseks tuleb kampaania
              korraldajale esitada ostutšekk.
              <p>
                Loosimises osalemiseks võib ühe ostutšeki numbri esitada ainult
                ühe korra. Sama ostutšeki mitmekordsel registreerimisel osaleb
                ainult esimesena saabunud registreering.
              </p>
              <p className="bold">Isikuandmete töötlemise kord</p>
              <p>
                Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
                isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
                kampaania läbiviimise eesmärgil.
              </p>
              <p>
                Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
                esitatud andmeid järgnevatel eesmärkidel:
              </p>
              <ul>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks;</li>
                <li>
                  kampaaniaga seotud koostööpartneritele info edastamiseks;
                </li>
                <li>võitjate välja loosimiseks ja nende teavitamiseks;</li>
                <li>võitjate nimede avaldamiseks kampaania veebilehel.</li>
              </ul>
              <p>
                Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
                poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
                aadressile: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
              </p>
              <p>
                Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
                10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;{' '}
                <a href="mailto:info@fazer.ee">info@fazer.ee</a>
              </p>
              <p>
                Kogutud andmeid säilitatakse kolm kuud alates kampaania
                lõpukuupäevast ehk kuni 01.06.2025. Võitjate täielik nimekiri on
                veebilehel üleval kuni 31.03.2025.{' '}
              </p>
            </li>
            <li>
              <span className="bold">03.03.2025</span> loositakse kell 12.00
              kõikide kampaanias osalejate vahel välja{' '}
              <span className="bold">
                5x romantiline minipuhkus kahele Schloss Fellin butiikhotellis
                väärtusega 850€. Auhinnafondi väärtus 4250€
              </span>
              <p>
                Auhinna loosimises osalevad kõik veebis registreerunud isikud
                kampaania reeglites esitatud perioodil (alates keskkonna
                avanemisest). Võitjat informeeritakse telefoni või meili teel.
                Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
              </p>
            </li>
            <li>
              Auhinna võitja nimi avaldatakse internetis aadressil{' '}
              <a href="http://www.geisha.ee">www.geisha.ee</a> hiljemalt
              loosimisele järgneval kolmandal tööpäeval. Võitjaga võetakse
              korraldaja esindaja poolt ühendust andmete ja auhinna
              kättetoimetamisviisi täpsustamiseks.
            </li>
            <li>
              Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
              kampaania tingimustele või kui ta ei ole alles hoidnud toote
              ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
              veebi vahendusel; samuti juhul, kui auhinna võitja ei ole
              hiljemalt kahe nädala jooksul alates, tema kui auhinna võitja nime
              avalikustamist internetis aadressil{' '}
              <a href="http://www.geisha.ee">www.geisha.ee</a> olnud veebis
              esitatud kontaktandmetel kättesaadav andmete täpsustamiseks, on
              korraldajal õigus jätta talle auhind üle andmata.{' '}
            </li>
            <li>
              Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
              korraldajale kasutada võitja nime meedias, reklaamides,
              PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat
              tasu maksmata ja vastavat kokkulepet sõlmimata.{' '}
            </li>
            <li>
              Kampaania käigus kampaanias osaleja poolt võidetud auhinda ei
              asendata võitja nõudmisel teist liiki auhinnaga ega hüvitata
              võitjale auhinna maksumust rahas, v.a juhul, kui auhind
              asendatakse teise samaväärse auhinnaga korraldaja otsusel.{' '}
            </li>
            <li>
              Auhinna võitjale üleandmisega seotud riiklikud maksud tasub
              korraldaja Eesti Vabariigi seadustes sätestatud korras ja
              tähtaegadel.{' '}
            </li>
            <li>
              Kampaanias osaleja poolt kampaania tingimuste eiramise või
              korraldajale enda kohta valede või ebatäpsete andmete esitamise
              korral, samuti muude ebaõnnestumiste korral (korraldajal ei
              õnnestu auhinna võitjaga kontakti saada), mis ei ole tingitud
              korraldaja süüst, ei vastuta korraldaja kampaanias osaleja
              auhindade loosimisest või auhinna kätteandmisest kõrvalejäämise
              eest.{' '}
            </li>
            <li>
              Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
              ühepoolne õigus katkestada kampaania ja jätta auhinnad välja
              loosimata ja/või auhinnad võitjatele üle andmata, teatades sellest
              koheselt kampaanias osalejatele meedia vahendusel.{' '}
            </li>
            <li>
              Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
              Vabariigi seadusandlusele.
            </li>
            <li>
              Kõik pretensioonid seoses kampaania korraldamise ja/või
              läbiviimisega tuleb saata kirjalikult kampaania korraldaja
              asukohta: Pärnu maantee 158/1, 11317 Tallinn märksõnaga{' '}
              <span className="bold">“KAMPAANIA"</span> hiljemalt 01.03.2025.
              Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta
              menetluse. Laekunud pretensioonidele vastatakse 3 tööpäeva
              jooksul. Kampaania lisainfo telefonil 650 2421;{' '}
              <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Rules;
